<template>
    <div class="cd-body bg-white p-2">
        <div class="p-2 mb-base">
            <div class="flex">
                <div class="mr-4 text-center">
                    <vs-avatar size="80px" src="https://i.imgur.com/mJNQNOr.jpeg"></vs-avatar>
                    <div>
                        <span>徐克宇</span>
                        <span class="text-sm">先生</span>
                    </div>
                </div>
                <div class="grid">
                    <div class="grid mb-4">
                        <div class="mb-4 cd-demo-tags flex flex-wrap cursor-pointer hover:text-primary hover:border-primary" @click="openPersonality">
                            <vs-chip class="hover:text-primary hover:border-primary" v-for="(item,index) in demo_cust_personality">{{ item }}</vs-chip>
                            <div class="flex items-center">
                                <span class="text-sm" v-if="demo_cust_personality.length == 0">客戶個性</span>
                                <feather-icon class="mx-1 text-primary" icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                            </div>
                        </div>
                        <div class="mb-4 cd-demo-tags flex flex-wrap cursor-pointer hover:text-primary hover:border-primary" @click="openDemand">
                            <vs-chip class="hover:text-primary hover:border-primary">臺北市</vs-chip>
                            <vs-chip class="hover:text-primary hover:border-primary">2000~2500萬</vs-chip>
                            <vs-chip class="hover:text-primary hover:border-primary">20~30坪</vs-chip>
                            <vs-chip class="hover:text-primary hover:border-primary">公寓</vs-chip>
                            <vs-chip class="hover:text-primary hover:border-primary">2房1廳2衛</vs-chip>
                            <vs-chip class="hover:text-primary hover:border-primary">坐北朝南</vs-chip>
                            <feather-icon class="mx-1 text-primary" icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                        </div>
                        <div class="cd-demo-tags flex flex-wrap cursor-pointer hover:text-primary hover:border-primary" @click="openPurpose">
                            <vs-chip class="hover:text-primary hover:border-primary" v-for="(item,index) in demo_buy_purpose">{{ item }}</vs-chip>
                            <div class="flex items-center">
                                <span class="text-sm" v-if="demo_buy_purpose.length == 0">購屋目的</span>
                                <feather-icon class="mx-1 text-primary" icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="p-4 mb-base max-w-md text-sm" style="background-color: #fee;">
            <span>服務紀錄</span>
            <div class="my-2">
                <vs-radio class="mr-4 mb-4" v-model="demo_src" vs-name="src" vs-value="1">來電</vs-radio>
                <vs-radio class="mr-4 mb-4" v-model="demo_src" vs-name="src" vs-value="2">外撥</vs-radio>
                <vs-radio class="mr-4 mb-4" v-model="demo_src" vs-name="src" vs-value="3">來信</vs-radio>
                <vs-radio class="mr-4 mb-4" v-model="demo_src" vs-name="src" vs-value="4">發信</vs-radio>
            </div>
            <div class="mb-4 cd-demo-tags flex flex-wrap cursor-pointer hover:text-primary hover:border-primary" @click="openServiceDemand">
                <vs-chip class="hover:text-primary hover:border-primary" v-for="(item,index) in demo_call_purpose">{{ item }}</vs-chip>
                <div class="flex items-center">
                    <span class="text-sm" v-if="demo_call_purpose.length == 0">服務需求</span>
                    <feather-icon class="mx-1 cursor-pointer text-primary" icon="PlusIcon" svgClasses="w-4 h-4"></feather-icon>
                </div>
            </div>
            <div class="my-2">
                <div class="flex justify-between">
                    <span>註記</span>
                    <span class="text-primary cursor-pointer hover:font-semibold">常用字句</span>
                </div>
                <textarea class="w-full cd-emr-textarea" rows="4" v-model="case_notes"></textarea>
            </div>
        </div>
        <div class="p-2 mb-base text-sm">
            <div>
                <span>進行中案件</span>
                <span class="ml-4 text-primary cursor-pointer hover:font-semibold">新增案件</span>
            </div>
            <div class="p-2 max-w-md border border-solid border-grey shadow-md">
                <div class="flex items-center">
                    <div>
                        <feather-icon class="mx-1 text-success" icon="HomeIcon" svgClasses="w-12 h-12"></feather-icon>
                        <div class="text-center">
                            <vs-dropdown class="cursor-pointer hover:font-semibold" vs-custom-content vs-trigger-click>
                                <!-- ▾ -->
                                <span class="text-sm text-primary">{{ demo_progress }}</span>
                                <vs-dropdown-menu class="whitespace-no-wrap">
                                    <ul style="min-width: 4rem">
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="demo_progress = '查詢中'">
                                            <span>查詢中</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="demo_progress = '考慮中'">
                                            <span>考慮中</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="demo_progress = '議價中'">
                                            <span>議價中</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="demo_progress = '貸款中'">
                                            <span>貸款中</span>
                                        </li>
                                        <li class="flex p-2 cursor-pointer hover:bg-primary hover:text-white" @click="demo_progress = '已簽約'">
                                            <span>已簽約</span>
                                        </li>
                                    </ul>
                                </vs-dropdown-menu>
                            </vs-dropdown>
                        </div>
                    </div>
                    <div class="ml-4">
                        <div>
                            <span>台北市士林區延平北路五段267巷13號十樓</span>
                        </div>
                        <div>
                            <vs-chip>公寓</vs-chip>
                            <vs-chip>2360萬</vs-chip>
                            <vs-chip>三房</vs-chip>
                            <vs-chip>近捷運</vs-chip>
                            <vs-chip>近公園</vs-chip>
                            <vs-chip>近小學</vs-chip>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <vs-popup :active.sync="personalityPopupActive" @close="closePersonality" title="客戶個性">
            <div class="flex flex-wrap">
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_cust_personality, '開朗') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_cust_personality, '開朗') ? 'text-primary' : ''">開朗</span>
                    <vs-checkbox class="hidden" v-model="demo_cust_personality" vs-value="開朗"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_cust_personality, '謹慎') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_cust_personality, '謹慎') ? 'text-primary' : ''">謹慎</span>
                    <vs-checkbox class="hidden" v-model="demo_cust_personality" vs-value="謹慎"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_cust_personality, '仔細') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_cust_personality, '仔細') ? 'text-primary' : ''">仔細</span>
                    <vs-checkbox class="hidden" v-model="demo_cust_personality" vs-value="仔細"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_cust_personality, '價格敏感') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_cust_personality, '價格敏感') ? 'text-primary' : ''">價格敏感</span>
                    <vs-checkbox class="hidden" v-model="demo_cust_personality" vs-value="價格敏感"></vs-checkbox>
                </label>
            </div>
        </vs-popup>
        <vs-popup :active.sync="demandPopupActive" @close="closeDemand" title="客戶偏好">
            <div class="flex flex-wrap">

            </div>
        </vs-popup>
        <vs-popup :active.sync="purposePopupActive" @close="closePurpose" title="購屋目的">
            <div class="flex flex-wrap">
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_buy_purpose, '自住') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_buy_purpose, '自住') ? 'text-primary' : ''">自住</span>
                    <vs-checkbox class="hidden" v-model="demo_buy_purpose" vs-value="自住"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_buy_purpose, '商用') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_buy_purpose, '商用') ? 'text-primary' : ''">商用</span>
                    <vs-checkbox class="hidden" v-model="demo_buy_purpose" vs-value="商用"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_buy_purpose, '投資') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_buy_purpose, '投資') ? 'text-primary' : ''">投資</span>
                    <vs-checkbox class="hidden" v-model="demo_buy_purpose" vs-value="投資"></vs-checkbox>
                </label>
            </div>
        </vs-popup>
        <vs-popup :active.sync="serviceDemandPopupActive" @close="closeServiceDemand" title="服務需求">
            <div class="flex flex-wrap">
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '詢問物件') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '詢問物件') ? 'text-primary' : ''">詢問物件</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="詢問物件"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '看房') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '看房') ? 'text-primary' : ''">看房</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="看房"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '討論') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '討論') ? 'text-primary' : ''">討論</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="討論"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '議價') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '議價') ? 'text-primary' : ''">議價</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="議價"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '貸款') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '貸款') ? 'text-primary' : ''">貸款</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="貸款"></vs-checkbox>
                </label>
                <label class="p-2 m-2 border border-solid cursor-pointer hover:text-primary" style="border-radius: 5px;" :style="checkSelected(demo_call_purpose, '簽約') ? 'border-color: #003f8e;' : 'border-color: #d6d6d6;'">
                    <span :class="checkSelected(demo_call_purpose, '簽約') ? 'text-primary' : ''">簽約</span>
                    <vs-checkbox class="hidden" v-model="demo_call_purpose" vs-value="簽約"></vs-checkbox>
                </label>
            </div>
        </vs-popup>
    </div>
</template>
<script>
export default {
    data() {
        return {
            demo_call_purpose: [],
            demo_progress: '考慮中',
            demo_buy_purpose: ['自住', '投資'],
            demo_type: null,
            demo_budget: null,
            case_notes: null,
            demo_cust_personality: ['開朗', '仔細', '價格敏感'],
            cust_notes: null,
            demo_src: null,
            personalityPopupActive: false,
            demandPopupActive: false,
            purposePopupActive: false,
            serviceDemandPopupActive: false,
        }
    },
    methods: {
        openPersonality() {
            document.body.style.overflow = 'hidden'
            this.personalityPopupActive = true
        },
        closePersonality() {
            document.body.style.overflow = 'visible'
            this.personalityPopupActive = false
        },
        openDemand() {
            document.body.style.overflow = 'hidden'
            this.demandPopupActive = true
        },
        closeDemand() {
            document.body.style.overflow = 'visible'
            this.demandPopupActive = false
        },
        openPurpose() {
            document.body.style.overflow = 'hidden'
            this.purposePopupActive = true
        },
        closePurpose() {
            document.body.style.overflow = 'visible'
            this.purposePopupActive = false
        },
        openServiceDemand() {
            document.body.style.overflow = 'hidden'
            this.serviceDemandPopupActive = true
        },
        closeServiceDemand() {
            document.body.style.overflow = 'visible'
            this.serviceDemandPopupActive = false
        },
        checkSelected(arr, str) {
            return _.includes(arr, str);
        }
    }
}
</script>
<style lang="css">
.cd-demo-tags .con-vs-chip{
    border: #666 1px solid;
    border-radius: 5px;
    background: #fff;
    font-size: 0.875rem;
}
</style>